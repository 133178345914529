<template>
  <div
    id="home"
    class="py-12"
  >
    <div v-if="isAuthenticated">
      <div class="w-full max-w-xs mx-auto mt-10 text-center">
        Logging you out...
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      email: 'jchiu.yin@gmail.com',
      password: 'secret',
      rememberMe: false,
    }
  },
  computed: {
    loading: function () {
      return this.authStatus === 'loading' && !this.isAuthenticated
    }
  },
  mounted(){
    this.logout()
  },
  methods: {
    logout: function () {
        this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
    }
  },
}
</script>
